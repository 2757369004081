import React from 'react'
import { css } from 'linaria'
import { styled } from 'linaria/react'
import { ManualRecommendation } from '../ManualRecommendation'
import Link from '../../Core/Link'
import SiteButton from '../../Core/SiteButton'
import { NavArrowRight } from 'iconoir-react'
import {
   getBoolByPropertyName,
   getStringByPropertyName,
} from '../../Core/Util/Helpers'

const ThematicProductsPresentationWrapper = styled.div`
   .thematic-products-col .retail-wrapper .carousel-component {
      padding-top: 1rem;
      /* Your specific custom styles for the Carousel component */
   }
   .border-radius-wrapper {
      overflow: hidden;
      border-radius: 8px;
   }
`

// Common grid style for image-first layout (desktop)
const imageFirstGridStyle = css`
   display: grid;
   grid-template-columns: 1fr 1fr; /* Equal columns on desktop */
   align-items: stretch; /* Stretch both columns to the tallest content */
   grid-template-areas: 'image content'; /* Image first, content second */

   /* On mobile, stack image on top regardless of imageFirst prop */
   @media (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-template-areas:
         'image'
         'content'; /* Image always on top in mobile */
   }
`

// Grid style for content-first layout (desktop)
const contentFirstGridStyle = css`
   display: grid;
   grid-template-columns: 1fr 1fr; /* Equal columns on desktop */
   align-items: stretch; /* Stretch both columns to the tallest content */
   grid-template-areas: 'content image'; /* Content first, image second */

   /* On mobile, stack image on top regardless of imageFirst prop */
   @media (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-template-areas:
         'image'
         'content'; /* Image always on top in mobile */
   }
`

// Image container (first or second column)
const imageContainerStyle = css`
   grid-area: image;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   /* height: 100%; */

   img {
      width: 100%;
      height: auto; /* Maintain aspect ratio */
      height: 100%;
      /* max-width: 1024px;
      max-height: 628px; */
      object-fit: cover;
   }
`

// Content container (first or second column) with default vertical centering
const contentContainerStyle = css`
   grid-area: content;
   padding: 20px; /* Ensure padding for content */
   display: flex;
   flex-direction: column;
   justify-content: center;
   background-color: white;

   /* Always left horizontally on mobile */
   @media (max-width: 768px) {
      align-items: flex-start !important; /* Force horizontal centering on mobile */
      text-align: left !important; /* Force text centering on mobile */
   }
`

// Conditional classes for horizontal alignment (desktop only)
const horizontalAlignmentClasses = {
   left: css`
      align-items: flex-start;
      text-align: left;
   `,
   right: css`
      align-items: flex-end;
      text-align: right;
   `,
   center: css`
      align-items: center;
      text-align: center;
   `,
}

export const ThematicProductsPresentation = ({ properties, products }) => {
   const bgColor = getStringByPropertyName(properties, 'backgroundColor')
   const textColor = getStringByPropertyName(properties, 'textColor')
   const imageSrc = getStringByPropertyName(properties, 'imageSrc')
   const title = getStringByPropertyName(properties, 'contentTitle')
   const imageFirst = getBoolByPropertyName(properties, 'imageFirst')
   const roundedCorners = getBoolByPropertyName(properties, 'rounded')
   const contentText = getStringByPropertyName(properties, 'contentText')
   const ctaLinkBtn = getStringByPropertyName(properties, 'ctaLinkBtn')
   const btnTitle = getStringByPropertyName(properties, 'btnTitle')
   const imageAlt = getStringByPropertyName(properties, 'imageAlt')
   const contentAlignment = getStringByPropertyName(
      properties,
      'contentAlignment'
   )
   const categoryLink = getStringByPropertyName(properties, 'ctaLinkImage')

   // Conditionally set the row layout class based on the `imageFirst` prop
   const rowClass =
      imageFirst === true ? imageFirstGridStyle : contentFirstGridStyle

   // Set the horizontal alignment class based on the `contentAlignment` prop (desktop only)
   const alignmentClass =
      horizontalAlignmentClasses[contentAlignment] ||
      horizontalAlignmentClasses.center

   return (
      <ThematicProductsPresentationWrapper className="py-32">
         <div className="px-128">
            <div
               className={`${
                  roundedCorners === true ? 'border-radius-wrapper' : ''
               }`}
            >
               <div className={rowClass}>
                  {imageFirst ? (
                     <>
                        {/* Image on the left */}
                        {categoryLink ? (
                           <Link
                              to={categoryLink}
                              className={imageContainerStyle}
                           >
                              <img src={imageSrc} alt={imageAlt || ''} />
                           </Link>
                        ) : (
                           <div className={imageContainerStyle}>
                              <img src={imageSrc} alt={imageAlt || ''} />
                           </div>
                        )}

                        {/* Content on the right */}
                        <div
                           style={{
                              backgroundColor: bgColor, // Will use default from contentContainerStyle if undefined
                           }}
                           className={`${contentContainerStyle} ${alignmentClass}`}
                        >
                           <h3
                              style={{
                                 color: textColor, // Will use default from contentContainerStyle if undefined
                              }}
                           >
                              {title}
                           </h3>
                           <p
                              style={{
                                 color: textColor, // Will use default from contentContainerStyle if undefined
                              }}
                           >
                              {contentText}
                           </p>
                           {btnTitle && (
                              <SiteButton
                                 className="py-8 px-16"
                                 href={ctaLinkBtn}
                                 styleType="solid"
                                 color="blue"
                                 size="medium"
                                 icon={true}
                              >
                                 {btnTitle}
                                 <NavArrowRight
                                    strokeWidth={2.0}
                                    style={{ marginLeft: '1rem' }}
                                 ></NavArrowRight>
                              </SiteButton>
                           )}
                        </div>
                     </>
                  ) : (
                     <>
                        {/* Content on the left */}
                        <div
                           style={{
                              backgroundColor: bgColor, // Will use default from contentContainerStyle if undefined
                           }}
                           className={`${contentContainerStyle} ${alignmentClass}`}
                        >
                           <h3
                              style={{
                                 color: textColor, // Will use default from contentContainerStyle if undefined
                              }}
                           >
                              {title}
                           </h3>
                           <p
                              style={{
                                 color: textColor, // Will use default from contentContainerStyle if undefined
                              }}
                           >
                              {contentText}
                           </p>
                           {btnTitle && (
                              <SiteButton
                                 style={{
                                    padding: '0.8rem 0.8rem 0.8rem 1.5rem',
                                 }}
                                 href={ctaLinkBtn}
                                 styleType="solid"
                                 color="blue"
                                 size="large"
                                 icon={true}
                              >
                                 {btnTitle}
                                 <NavArrowRight
                                    strokeWidth={2.0}
                                    style={{ marginLeft: '1rem' }}
                                 ></NavArrowRight>
                              </SiteButton>
                           )}
                        </div>
                        {/* Image on the right */}
                        {categoryLink ? (
                           <Link
                              to={categoryLink}
                              className={imageContainerStyle}
                           >
                              <img src={imageSrc} alt={imageAlt || ''} />
                           </Link>
                        ) : (
                           <div className={imageContainerStyle}>
                              <img src={imageSrc} alt={imageAlt || ''} />
                           </div>
                        )}
                     </>
                  )}
               </div>
            </div>
         </div>

         {/* ManualRecommendation Section */}
         {products?.length > 0 && (
            <div className="thematic-products-col">
               <ManualRecommendation
                  properties={properties}
                  products={products}
                  hideHeader={true}
               />
            </div>
         )}
      </ThematicProductsPresentationWrapper>
   )
}
