import React from 'react'
import { styled } from 'linaria/react'
import SiteButton from '../SiteButton'
import { NavArrowRight } from 'iconoir-react'
import { theme } from '../../Theme'

const ComponentBottomContainer = styled.div`
   h1 {
      font-size: calc(1vw + 1.2rem);
   }

   .separation-line {
      ${theme.above.md} {
         margin-top: 0.1rem;
      }
      ${theme.above.xl} {
         margin-top: 1.6rem;
      }
   }
`

// hideLineInDesktop altreantive as prop
const ComponentBottom = ({ ctaText, ctaLink, ctaLinkNewWindow }) => {
   return (
      <ComponentBottomContainer className="row seperator d-flex align-items-center mb-32">
         {/* <div className={`separation-line col 'd-md-block' ${hideLineInDesktop ? 'd-lg-none' : ''}`}> */}
         <div className="col d-block d-md-none">
            <hr />
         </div>

         <div className="col-auto d-block d-md-none">
            <SiteButton
               style={{ padding: '0.8rem 0.8rem 0.8rem 1.5rem' }}
               href={ctaLink}
               target={ctaLinkNewWindow ? "_blank" : "_self"}
               styleType="solid"
               color="blue"
               size="large"
               icon={true}
            >
               {ctaText}
               <NavArrowRight
                  strokeWidth={2.0}
                  style={{ marginLeft: '1rem' }}
               ></NavArrowRight>
            </SiteButton>
         </div>
      </ComponentBottomContainer>
   )
}

export default ComponentBottom
