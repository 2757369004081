import React, { useState, useEffect } from 'react'
import MaxWidth from '../../Layout/MaxWidth'
import { styled } from 'linaria/react'
import { TextContent } from './TextContent'
import { LinkButton } from '../../ui/Button'
import { theme } from '../../Theme'
import { NavArrowRight } from 'iconoir-react'
import SiteButton from '../../Core/SiteButton'
import Image from '@jetshop/ui/Image/Image'
import { pxToRem } from '../../../utils/css'
import Link from '../../Core/Link'

const BubbleWidgetContainer = styled.div`
   background-color: #ededed;

   .bubble-items-container {
      display: flex;
      overflow-x: auto;
      column-gap: clamp(0.8rem,2vw,3rem);
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      justify-content: flex-start;
      ${theme.above.md} { 
         justify-content: center;
      }
   }

   .bubbles {
      width: 6.5rem;
      height: auto;
   }

   .bubbles-title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
   }

   .bubbles-link {
      color: var(--color-black);
      word-break: break-word;
      font-weight: 700;
      font-size: 1rem;
      ${theme.below.lg}{
         font-size: 0.9rem;
      } 
   }

   .bubble-image-container div[data-flight-image-container]:hover {
      cursor: pointer;
      border-radius: 500px;
      transition: box-shadow 0.5s, scale 0.3s ease;
      box-shadow: 0 0 0 6px rgba(25, 81, 120, 0.2);
      scale: 1.01;
   }

   .bubble-image-container div[data-flight-image-container] {
        border-radius: 500px;
        transition: box-shadow 0.5s ease;
    }

   .bubble-image-container div[data-flight-image-container]:before {
      content: "";
      z-index: 1;
      position: absolute;
      border-radius: 500px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      background: linear-gradient(
         180deg,
         rgba(255, 255, 255, 0.5) 0%,
         rgba(255, 255, 255, 0) 50%
      );
   }

   .bubbles img {
      object-fit: cover !important;
      width: 100% !important;
      vertical-align: top;
      position: absolute !important;
      top: -5px !important;
      left: 0 !important;
      height: 100% !important;
      padding: 0px !important;
      scale: 1.05;
   }

   .bubble-image-container {
      min-width: 4.5rem;
      max-width: 6.5rem;
   }
`
//Has to be changed if height changes
const threshold = 270;

export const BubbleWidget = ({ properties }) => {
   // const [isOverflowing, setIsOverflowing] = useState(true);

   // useEffect(() => {
   //    const handleOverflow = () => {
   //      const element = document.getElementById('bubbles-container');
   //      if (element && element.scrollHeight <= threshold) {
   //        setIsOverflowing(true);
   //      } else {
   //        setIsOverflowing(false);
   //      }
   //    };
  
   //    handleOverflow();
  
   //    // Event listener to check for overflow on window resize
   //    window.addEventListener('resize', handleOverflow);
  
   //    // Cleanup function to remove the event listener
   //    return () => {
   //      window.removeEventListener('resize', handleOverflow);
   //    };
   // }, []);

   return (
      <BubbleWidgetContainer className="container-fluid py-16" id="bubbles-container">
         <div className="bubble-items-container">
            {properties?.children?.map((bubbleItem, i) => {
               return (
                <div className="bubbles text-center my-2" key={i}>
                    <Link to={bubbleItem?.link}> 
                    <div className="bubble-image-container px-0">
                        <Image src={bubbleItem?.image} alt={bubbleItem?.altText} aspect="1:1"></Image>
                    </div>
                    </Link>
                    <div className="bubbles-title pt-8">
                        <Link className="bubbles-link" title={bubbleItem?.text} to={bubbleItem?.link}>
                            {bubbleItem?.text}
                        </Link>
                    </div>
                </div>
               )
            })}
         </div>
      </BubbleWidgetContainer>
   )
}