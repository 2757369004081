import React, { useState, useEffect } from 'react'
import MaxWidth from '../../Layout/MaxWidth'
import { styled } from 'linaria/react'
import { TextContent } from './TextContent'
import { LinkButton } from '../../ui/Button'
import { theme } from '../../Theme'
import { Above, Below } from '@jetshop/ui/Breakpoints'

const StepsContainer = styled(MaxWidth)`
   margin-bottom: -4rem;

   h1,
   h2,
   h3,
   h4,
   h5 {
      color: black;
      font-family: var(--font-industry);
   }

   h2 {
      font-size: clamp(1.6rem, 3vw, 2rem);
   }

   p {
      color: black;
   }

   .header {
      display: flex;
      justify-content: center;
      gap: 1rem;
      margin-bottom: 8rem;

      ${theme.below.sm} {
         margin-bottom: 1rem;
         justify-content: flex-start;
      }
   }

   .step-image {
      width: 180px;
      height: 180px;
      border-radius: 50%;
      object-fit: cover;

      ${theme.above.sm} {
         position: absolute;
         width: clamp(200px, 20vw, 246px);
         height: clamp(200px, 20vw, 246px);
         /* height: 246px; */
         top: 50%;
         transform: translateY(-80%);
      }
      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.07);
      margin: auto 0;
   }

   .step-container {
      position: relative;
      margin-bottom: 2rem;

      ${theme.above.sm} {
         margin-bottom: 7rem;
      }
      ${theme.above.xl} {
         margin-bottom: 10rem;
      }

   }

   .step-text-container {
      background-color: white;
      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.07);
      border-radius: 0px 0px 32px 0px;
      display: flex;
      padding: 2.5rem 1rem;
      margin-left: 0;
      margin-right: 0;

      ${theme.above.sm} {
         justify-content: end;
         margin-left: 3.25rem;
         padding: 4rem 1.5rem 1.5rem 0rem;
      }

      ${theme.above.xl} {
         margin-left: 4.25rem;
         padding: 4rem 1.5rem 1.5rem 0rem;
      }
   }

   .step-text {
      width: 100%;
      ${theme.above.sm} {
         width: calc(100% - 190px);
      }
   }

   .image-container-mobile {
      text-align: center;
   }
`

export const Steps = ({ properties }) => {
   return (
      <StepsContainer
         className={`${properties?.marginTop} ${properties?.marginBottom}`}
      >
         <div className="header">
            <h1>{properties?.title}</h1>
            <img src={properties?.icon} alt={properties?.title}></img>
         </div>
         <div className="row gx-5">
            {properties?.children?.map((step, index) => {
               return (
                  <>
                     <Above breakpoint="sm">
                        <div
                           key={index}
                           className="col-lg-6 col-12 step-container"
                        >
                           <div className="row gx-0 step-text-container">
                              <div className="col-12 mb-3 step-text">
                                 <h2 className="mb-3">{step?.title}</h2>
                                 <TextContent text={step?.text}></TextContent>
                              </div>
                           </div>
                           <img
                              src={step?.img}
                              alt={step?.altText}
                              className="step-image"
                           ></img>
                        </div>
                     </Above>
                     <Below breakpoint="sm">
                        <div
                           key={index}
                           className="col-lg-6 col-12 step-container"
                        >
                           <div className="row step-text-container">
                              <div className="col-12 mb-4 step-text">
                                 <h2 className="mb-3">{step?.title}</h2>
                                 <TextContent text={step?.text}></TextContent>
                              </div>
                              <div className="col-12 image-container-mobile">
                                 <img
                                    src={step?.img}
                                    alt={step?.altText}
                                    className="step-image"
                                 ></img>
                              </div>
                           </div>
                        </div>
                     </Below>
                  </>
               )
            })}
         </div>
      </StepsContainer>
   )
}
