import React, { useState, useEffect } from 'react'
import { VideoPlayer } from './VideoPlayer'
import { TextContent } from './TextContent'
import { ImageTextContent } from './ImageTextContent'

export const ThreeColumn = ({ properties }) => {
   return (
      <>
         {properties?.children?.length > 0 && (
            <div
               style={{
                  maxWidth: properties?.fullWidth && '100%',
                  padding: properties?.fullWidth && '0px',
               }}
               className={`three-column px-128 ${properties?.marginTop} ${properties?.marginBottom}`}
            >
               <div className="row gx-3 gy-3 m-0">
                  {properties?.children.map((component, index) => {
                     if (component?.videoId) {
                        return (
                           <div key={index} className={`col-md-4 col-12`}>
                              <VideoPlayer
                                 title={component?.title}
                                 videoId={component?.videoId}
                              ></VideoPlayer>
                           </div>
                        )
                     } else if (component?.content) {
                        return (
                           <div key={index} className="col-md-4 col-12">
                              <TextContent text={component?.content} />
                           </div>
                        )
                     } else if (component?.image && component?.customContent) {
                        return (
                           <div key={index} className="col-md-4 col-12">
                              <img
                                 style={{ width: '100%' }}
                                 src={component?.image}
                                 alt={component?.altText}
                              ></img>
                              <ImageTextContent
                                 innerClassName={
                                    component?.marginTopCustomContent
                                 }
                                 customContent={component?.customContent}
                              />
                           </div>
                        )
                     } else {
                        return (
                           <div key={index} className="col-md-4 col-12">
                              <img
                                 style={{ width: '100%' }}
                                 src={component?.img}
                                 alt={component?.altText}
                              ></img>
                           </div>
                        )
                     }
                  })}
               </div>
            </div>
         )}
      </>
   )
}
