import React, { useState, useEffect } from 'react'
import HelloRetailCarousel from '../HelloRetail/Carousel'
import { UseHelloRetail } from '../HelloRetail/UseHelloRetail'
import { HelloRetailToJetshopCarousel } from '../HelloRetail/HelloRetailToJetshop'

export const HelloRetailRecommendation = ({ recommendationId, title }) => {
   const [loaded, setLoaded] = useState(false)

   const helloRetailKey = { value: recommendationId }

   const { helloRetailRecoms, helloRetailLoading } = UseHelloRetail([
      helloRetailKey,
   ])

   return (
      <div className='retail-wrapper'>
         <HelloRetailToJetshopCarousel
            className="py-32"
            products={helloRetailRecoms?.length > 0 ? helloRetailRecoms[0]?.products : []}
            loading={helloRetailLoading}
            title={title}
            render={modifiedResults => <HelloRetailCarousel {...modifiedResults} />}
         />
      </div>
   )
}
