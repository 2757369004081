import React, { useState, useEffect } from 'react'
import { VideoPlayer } from './VideoPlayer'
import { TextContent } from './TextContent'
import { ImageTextContent } from './ImageTextContent'
import SiteButton from '../../Core/SiteButton'
import { NavArrowRight } from 'iconoir-react'

export const TwoColumn = ({ properties }) => {
   const textDesktopRight = 'text-start text-md-end'
   const textDesktopLeft = 'text-start'
   const invertOrder = properties?.invertOrder

   return (
      <>
         {properties?.children?.length > 0 && (
            <div
               style={{
                  maxWidth: properties?.fullWidth && '100%',
                  padding: properties?.fullWidth && '0px',
               }}
               className={`two-column px-128 ${properties?.marginTop} ${properties?.marginBottom}`}
            >
               <div className="row gx-3 gy-3 m-0">
                  {properties?.children.map((component, index) => {
                     if (component?.videoId) {
                        return (
                           <div
                              key={index}
                              className={`${'col-md-6 col-12'} ${
                                 invertOrder ? 'order-1' : ''
                              }`}
                           >
                              <VideoPlayer
                                 title={component?.title}
                                 videoId={component?.videoId}
                              ></VideoPlayer>

                              {component?.ctaLink && (
                                 <div>
                                    <SiteButton
                                       style={{
                                          padding:
                                             '0.8rem 0.8rem 0.8rem 1.5rem',
                                          color: 'white',
                                       }}
                                       className="mt-16"
                                       href={component?.ctaLink}
                                       styleType="solid"
                                       color="blue"
                                       size="medium"
                                       icon={true}
                                    >
                                       {component?.ctaText}
                                       <NavArrowRight
                                          strokeWidth={2.0}
                                          style={{ marginLeft: '1rem' }}
                                       ></NavArrowRight>
                                    </SiteButton>
                                 </div>
                              )}
                           </div>
                        )
                     } else if (component?.content) {
                        return (
                           <div
                              key={index}
                              className={`${'col-md-6 col-12'} ${
                                 invertOrder ? 'order-2' : ''
                              }`}
                           >
                              <TextContent
                                 text={component?.content}
                                 innerClassName={
                                    component?.textAlignment == 'right'
                                       ? textDesktopRight
                                       : textDesktopLeft
                                 }
                              />
                           </div>
                        )
                     } else if (component?.image && component?.customContent) {
                        return (
                           <div
                              key={index}
                              className={`${'col-md-6 col-12'} ${
                                 invertOrder ? 'order-1' : ''
                              }`}
                           >
                              <img
                                 style={{ width: '100%' }}
                                 src={component?.image}
                                 alt={component?.altText}
                              ></img>
                              <ImageTextContent
                                 innerClassName={
                                    component?.marginTopCustomContent
                                 }
                                 customContent={component?.customContent}
                              />
                           </div>
                        )
                     } else {
                        return (
                           <div
                              key={index}
                              className={`${'col-md-6 col-12'} ${
                                 invertOrder ? 'order-1' : ''
                              }`}
                           >
                              <img
                                 style={{ width: '100%' }}
                                 src={component?.img}
                                 alt={component?.altText}
                              ></img>
                           </div>
                        )
                     }
                  })}
               </div>
            </div>
         )}
      </>
   )
}
