import React from 'react'
import { styled } from 'linaria/react'
import ComponentHeader from '../Core/Common/ComponentHeader'
import ComponentBottom from '../Core/Common/ComponentBottom'
import Link from '../Core/Link'

const BrandContainer = styled.div`
   .brand-logo {
      z-index: 10;
      position: relative;
   }

   .brand::before {
      background: ${props => `url("${props.backgroundImage}") no-repeat`};
      background-size: cover;
      content: ' ';
      display: block;
      position: relative;
      height: 8rem;
      border-radius: 8px;
      scale: 1;
      transition: all 0.5s;
   }

   .brand:hover::before {
      scale: 1.01;
      transition: all 0.25s;
   }

   .brand img {
      border-radius: 8px;
   }

   .brand:hover .brand-logo img {
      transition: all 0.25s;
      scale: 1.05;
      box-shadow: 0 1px 32px rgba(0, 0, 0, 0.14);
   }

   .brand-logo img {
      width: 14rem;
      overflow: visible;
      margin-top: -32px;
      margin-right: 16px;
      backdrop-filter: blur(5px);
      background: rgba(255, 255, 255, 0.95);
      border-radius: 128px;
      transition: all 0.5s;
      scale: 1;
      box-shadow: 0 1px 16px rgba(0, 0, 0, 0.07);
   }

   @media (min-width: 768px) and (max-width: 1200px) {
      .brand-logo img {
         width: 8rem !important;
      }
   }

   @media (max-width: 1200px) {
      .brand {
         margin-bottom: 1rem;
      }

      .brand-logo {
         justify-content: center !important;
         z-index: 10;
      }

      .brand::before {
         height: 6rem;
      }

      .brand-logo img {
         width: 6rem;
         margin-right: 0 !important;
      }
   }
`

export const BrandList = ({ properties }) => {
   const brands = properties?.children

   return (
      <div className='px-128 py-32'>
         <div className="container-fluid p-0 m-0">
            {
               properties?.title && <ComponentHeader title={properties?.title} ctaText={properties?.ctaText} ctaLink={properties?.ctaLink}></ComponentHeader>
            }
            <div className="row gx-3">
               {brands?.map((brand, i) => {
                  return (
                     <BrandContainer
                        key={i}
                        className={`col-6 col-md-3 ${i < 4 ? "mb-md-5" : ""}`}
                        backgroundImage={brand?.backgroundImage}
                     >
                        <Link to={brand?.iink}>
                           <div className="brand w-100">
                              <div className="brand-logo d-flex justify-content-end">
                                 <img
                                    className="px-64 py-16"
                                    alt={brand?.logoAlt}
                                    src={brand?.logo}
                                 />
                              </div>
                           </div>
                        </Link>
                     </BrandContainer>
                  )
               })}
            </div>
            {
               properties?.title && <ComponentBottom title={properties?.title} ctaText={properties?.ctaText} ctaLink={properties?.ctaLink}></ComponentBottom>
            }
         </div>
      </div>
   )
}


