import React from 'react'

import { styled } from 'linaria/react'
import { css, cx } from 'linaria'
import { theme } from '../Theme'
import { pxToRem } from '../../utils/css'
import { Link } from 'react-router-dom'
import MaxWidth from '../Layout/MaxWidth'
import { LinkButton } from '../ui/Button'
import { ImageCover } from '../ui/ImageCover'

const Container = styled(MaxWidth)`
   margin-bottom: ${theme.spacing.m};
`

const Grid = styled.div`
   display: grid;
   grid-gap: ${theme.spacing.m};
   grid-template-columns: 1fr;
   ${theme.above.lg} {
      &[data-layout='2/1'] {
         grid-template-columns: 2fr 1fr;
      }
      &[data-layout='1/2'] {
         grid-template-columns: 1fr 2fr;
      }
   }
`

const titleCss = css`
   text-transform: uppercase;
   color: white;
   text-align: center;
   font-weight: ${theme.font.weight.bold};
   font-size: ${pxToRem(36)};
   width: 100%;
   background: rgba(0,0,0,0.6);
   padding: 0.25rem 1rem 1.25rem;
   text-shadow: 0 0 5px #000;
   
   ${theme.above.xl} {
      font-size: ${pxToRem(50)};
   }


   ${theme.below.sm} {
      font-size: ${pxToRem(24)};
   }
`

const IconImage = styled.img`
   max-width: ${pxToRem(60)};
   margin-bottom: 1rem;
`

const withMarginBottom = css`
   margin-bottom: ${theme.spacing.xs};
`

const textCss = css`
   font-size: ${theme.font.size.m};
   color: white;
   background: rgba(0,0,0,0.6);
   padding: 1rem 1rem 0;
   width: 100%;
   text-align: center;

   ${theme.below.sm} {
      font-size: ${pxToRem(14)};
   }
`

const StyledCard = styled(ImageCover)`
   min-height: 315px;
   ${theme.above.lg} {
      font-size: ${pxToRem(50)};
      min-height: 421px;
   }
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 1rem 0;
`

const ColorCard = ({
   title,
   backgroundImage,
   backgroundColor,
   buttonText,
   icon,
   buttonLink,
   ingress,
   text,
   ...props
}) => (
   <StyledCard
      data-has-background-image={false}
      className={cx(backgroundColor && `background-${backgroundColor}`)}
      {...props}
   >
      {icon && <IconImage src={icon} />}
      {title && <h1 className={cx(titleCss)}>{title}</h1>}
      {text && <p className={cx(textCss, withMarginBottom)}>{text}</p>}
      <LinkButton
         title={buttonText}
         to={buttonLink}
         isInverted
         isBig
         color={backgroundColor}
      />
   </StyledCard>
)

const ImageCard = ({
   title,
   text,
   backgroundImage,
   backgroundColor,
   buttonText,
   buttonLink,
   ...props
}) => {
   return (
      <Link to={buttonLink} style={{ textDecoration: 'none' }}>
         <StyledCard
            data-has-background-image={!!backgroundImage}
            backgroundImage={backgroundImage}
            className={cx(backgroundColor && `background-${backgroundColor}`)}
            {...props}
         >
            {text && <p className={cx(textCss)}>{text}</p>}
            {title && <h1 className={cx(titleCss, withMarginBottom)}>{title}</h1>}
            <LinkButton isBig title={buttonText} to={buttonLink} />
         </StyledCard>
      </Link>
   )
}

export const CtaCards = ({ layout, children }) => (
   <Container>
      <Grid data-layout={layout}>
         {children &&
            children.map((child, index) => {
               if (child.type === 'image') {
                  return <ImageCard key={index} {...child} />
               }
               if (child.type === 'color') {
                  return <ColorCard key={index} {...child} />
               }

               return null
            })}
      </Grid>
   </Container>
)
