import React from 'react'

import { styled } from 'linaria/react'
import { css, cx } from 'linaria'
import { theme } from '../Theme'
import { getSrcSet, pxToRem } from '../../utils/css'
import MaxWidth from '../Layout/MaxWidth'

const Wrapper = styled(MaxWidth)`
   padding: ${pxToRem(20)};
   ${theme.above.md} {
      padding: ${pxToRem(40)} ${pxToRem(70)};
   }

   .image-right {
      .image-wrapper {
         order: 2;
      }
      .text-wrapper {
         order: 1;
      }
   }
`

const column = css`
   ${theme.above.sm} {
      display: grid;
      grid-gap: ${theme.spacing.xs};
      grid-template-columns: 1fr 1fr;
   }
`

const Item = styled.div`
   margin: ${pxToRem(40)} 0;
   .image-wrapper {
      margin-bottom: ${pxToRem(30)};
      width: 100%;
      ${theme.above.sm} {
         margin-bottom: 0;
      }
      img {
         width: 100%;
         height: 100%;
         max-height: ${pxToRem(370)};
         object-fit: cover;
      }
   }
   .text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      // Override the default link color
      a {
         color: var(--color-vb-blue) !important;
      }
      // Just general margins
      h1,
      h2,
      h3,
      h4 {
         margin-bottom: ${theme.spacing.xs};
      }
   }
`

export const ImageColumn = ({ children }) => {
   return (
      <Wrapper>
         {children &&
            children.map((item, index) => {
               return (
                  <Item key={index} className={cx(column, item.type)}>
                     <div className="image-wrapper">
                        <img
                           src={item.img}
                           srcSet={getSrcSet(item.img)}
                           alt={item.altText}
                        />
                     </div>
                     {item.text ? (
                        <div
                           key={index}
                           dangerouslySetInnerHTML={{ __html: item.text }}
                           className="text-wrapper"
                        />
                     ) : (
                        <div className="text-wrapper" />
                     )}
                  </Item>
               )
            })}
      </Wrapper>
   )
}
export default ImageColumn
