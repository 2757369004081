import React, { useState, useEffect } from 'react'
import MaxWidth from '../../Layout/MaxWidth'
import { styled } from 'linaria/react'
import { TextContent } from './TextContent'
import { LinkButton } from '../../ui/Button'
import { theme } from '../../Theme'
import { fetchKundoDialogById, fetchLatestKundoDialogs } from '../../Kundo/Api'
import SiteButton from '../../Core/SiteButton'
import { NavArrowRight } from 'iconoir-react'
import KundoCardSkeleton from '../../Core/Skeletons/KundoCardSkeleton'
import ComponentHeader from '../../Core/Common/ComponentHeader'
import ComponentBottom from '../../Core/Common/ComponentBottom'

const KundoContainer = styled.div`
   & .dialog {
      background: #fff;
      border-radius: 1rem;
      box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.07);
   }

   .kundo-profile-image {
      border-radius: 50%;
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1 / 1;
   }

   & h4 {
      font-family: var(--font-grotesk) !important;
      font-weight: 700;
   }

   .seperator h3 {
      font-weight: 700;
   }

   .seperator hr {
      height: 0.5rem;
      border-top-width: 0.5rem;
      border-color: #505050;
   }

   .injected-content {
      word-break: break-all;
   }

   .injected-content a {
      color: var(--color-vb-blue);
      text-decoration: revert;
   }

   .injected-content img {
      width: 200px;
      object-fit: cover;
      height: 200px;
   }
`

const DialogContainer = styled.div`
   & > div:nth-child(2) {
      ${theme.below.md} {
         display: none;
      }
   }
`
export const Kundo = ({ properties }) => {
   const [isLoading, setIsLoading] = useState(true);
   const [dialogs, setDialogs] = useState([])
   const inactive = properties?.inactive;

   useEffect(() => {
      const fetchData = async () => {
        var res = await fetchLatestKundoDialogs()
        setDialogs(res)
        setIsLoading(false)
      }
      fetchData()
   }, [])

   if(inactive){
      return <></>
   }

   return (
      <KundoContainer className="container-fluid px-128 my-32">
         <ComponentHeader title="Frågor & svar från våra kunder" ctaText="Läs mer i vårt kundforum" ctaLink="https://kundforum.verktygsboden.se/org/verktygsboden" ctaLinkNewWindow={true}></ComponentHeader>
         <DialogContainer className="row">
            {isLoading ? (
               <>
                  <div className="col-12 col-md-6 mb-32">
                     <KundoCardSkeleton></KundoCardSkeleton>
                     <KundoCardSkeleton className="mt-32" displaced={true}></KundoCardSkeleton>
                  </div>
                  <div className="col-12 col-md-6 mb-32">
                     <KundoCardSkeleton></KundoCardSkeleton>
                     <KundoCardSkeleton className="mt-32" displaced={true}></KundoCardSkeleton>
                  </div>
               </>
            ) : (
               dialogs?.map((dialog, i) => {
                  return (
                     <div key={i} className="col-12 col-md-6 mb-32">
                        <div className="row question">
                           <div className="col-2 col-md-1 p-0 m-0">
                              <img
                                 className='kundo-profile-image'
                                 alt="Kundo profile"
                                 src={dialog?.user?.gravatar_image}
                              />
                           </div>
                           <div className="col-10">
                              <div className="dialog p-32">
                                 <h4>{dialog?.user?.first_name} frågar: {dialog?.title}</h4>
                                 <div
                                    className="mb-0 injected-content"
                                    dangerouslySetInnerHTML={{
                                       __html: dialog?.text,
                                    }}
                                 ></div>
                              </div>
                           </div>
                           <div className="col-0 col-md-1"></div>
                        </div>

                        <div className="row answer mt-32">
                           <div className="col-2 col-md-1"></div>
                           <div className="col-2 col-md-1 p-0 m-0">
                              <img
                              className='kundo-profile-image'
                                 alt="Kundo profile"
                                 src={
                                    dialog?.comments[0]?.user?.gravatar_image
                                 }
                              />
                           </div>
                           <div className="col-8 col-md-10">
                              <div className="dialog p-32">
                                 <h4>
                                    {dialog?.comments[0]?.user?.first_name}{' '}
                                    svarar
                                 </h4>
                                 <div
                                    className="mb-0 injected-content"
                                    dangerouslySetInnerHTML={{
                                       __html: dialog?.comments[0]?.text,
                                    }}
                                 />
                                 <SiteButton className="mt-16 py-8 px-16" href={dialog?.absolute_url} target="_blank" styleType="hollow" color="blue" size="small">
                                    Se hela svaret
                                 </SiteButton>
                              </div>
                           </div>
                        </div>
                     </div>
                  )
               })
            )}
         </DialogContainer>
            <ComponentBottom
               title="Frågor & svar från våra kunder"
               ctaText="Läs mer i vårt kundforum"
               ctaLink="https://kundforum.verktygsboden.se/org/verktygsboden"
               ctaLinkNewWindow={true}
            />
      </KundoContainer>
   )
}