import React from 'react'
import { styled } from 'linaria/react'
import { theme } from '../../Theme'

export const ImageTextWrapper = styled.div`
   div > h1,
   div > h2,
   div > h3,
   div > h4 {
      margin-bottom: ${theme.spacing.xxs};
      font-family: var(--font-industry) !important;
   }
   
   p {
      margin: 0;
   }
`

export const ImageTextContent = ({ innerClassName, customContent }) => {
   return (
      <ImageTextWrapper>
         <div
            className={`${innerClassName}`}
            dangerouslySetInnerHTML={{ __html: customContent }}
         />
      </ImageTextWrapper>
   )
}
