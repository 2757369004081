import React from 'react'
import ArticlesViewer from '../../Pages/Blog/Components/ArticlesViewer'

const constructArticlesData = data => {
   const articlesArr = data?.children
   const formattedArticlesArr =
      articlesArr?.reduce((accumulator, obj) => {
         accumulator.push({
            id: '',
            title: obj?.titel,
            articleText: obj?.textContent,
            imageSrc: obj?.img,
            routePath: obj?.ctaLink,
         })
         return accumulator
      }, []) ?? []

   return {
      name: data?.title,
      // primaryRoute: '',
      articles: formattedArticlesArr,
   }
}

export const ArticlesViewerManual = ({ properties }) => {
   const articlesData = constructArticlesData(properties)
   return (
      <ArticlesViewer articlesData={articlesData} />
   )
}

export default ArticlesViewerManual