import React from 'react'
import { styled } from 'linaria/react'
import { theme } from '../../Theme'

export const RichContentStyleWrapper = styled.div`
   h1,
   h2,
   h3,
   h4 {
      margin-bottom: ${theme.spacing.xs};
      font-family: var(--font-industry);
   }

   p {
      margin: 0;
   }
`

export const TextContent = ({ text, children, innerClassName, ...props }) => {
   return (
    <RichContentStyleWrapper {...props}>
        <div className={`${innerClassName}`} dangerouslySetInnerHTML={{ __html: text }} />
        {children}
    </RichContentStyleWrapper>
   )
}