import React, { useEffect } from 'react'
import Carousel from '../HelloRetail/Carousel'
import { useQuery } from 'react-apollo'
import manualProductsRecommendationQuery from './ManualProductsRecommendationQuery.gql'
import { getBoolByPropertyName } from '../Core/Util/Helpers'

function prepareArticleNumbersToFetch(products) {
   if (!products) return []

   const extractedArticleNumbersArr = products?.reduce(
      (accumulatedArticleNumbers, item) => {
         const artNr = item?.properties[0]?.value?.articleNumber
         if (artNr !== null && artNr !== undefined) {
            accumulatedArticleNumbers.push(artNr)
         }
         return accumulatedArticleNumbers
      },
      []
   )

   return extractedArticleNumbersArr
}

export const ManualRecommendation = ({ properties, products, hideHeader }) => {
   const carouselTitle = properties?.find(field => field?.name == 'title')?.value
      ?.string
   const hideHeaderFromCms = getBoolByPropertyName(properties, 'hideHeader')
   const articleNumbersToFetch = prepareArticleNumbersToFetch(products)
   const shouldHideHeader = hideHeaderFromCms || hideHeader;

   // Issue: When any of the article numbers is not found on the server,
   // the request fails with a 'ProductNotFound' error.
   const { data, error, loading } = useQuery(
      manualProductsRecommendationQuery,
      {
         variables: {
            articles: [...articleNumbersToFetch],
         },
         // Skip query if articleNumbersToFetch is falsy/empty-array
         skip: !articleNumbersToFetch?.length || !articleNumbersToFetch,
      }
   )

   if (!properties || !products) {
      return <></>
   }

   return (
      <div className="retail-wrapper">
        <Carousel
            className="py-32 carousel-component"
            products={data?.products}
            loading={loading}
            title={carouselTitle}
            properties={properties}
            hideHeader={shouldHideHeader}
         ></Carousel>
      </div>
   )
}
