import React from 'react';

const KundoCardSkeleton = ({ displaced, className, ...props}) => {
   return (
      <div className={`row placeholder-wave ${className}`} {...props}>
        {
            displaced && <div className="col-0 col-md-2 col-lg-1"></div>
        }
         <div className="col-2 col-md-2 col-lg-1">
            <img className="placeholder placeholder-wave col-12" alt="Placeholder" />
         </div>
         <div className="col-10 col-md-8 col-lg-10">
            <div className="dialog p-32">
               {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
               <h4 className="placeholder placeholder-lg w-50 col-12"/>

               <span className="placeholder col-7"></span>
               <span className="placeholder col-4"></span>
               <span className="placeholder col-4"></span>
               <span className="placeholder col-6"></span>
               <span className="placeholder col-8"></span>
               <span className="placeholder col-3"></span>
               <span className="placeholder col-6"></span>
            </div>
         </div>
      </div>
   )
}

export default KundoCardSkeleton
