import React, { useState, useEffect } from 'react'
import MaxWidth from '../../Layout/MaxWidth'
import { styled } from 'linaria/react'
import { TextContent } from './TextContent'
import { LinkButton } from '../../ui/Button'
import { theme } from '../../Theme'
import { NavArrowRight } from 'iconoir-react'
import SiteButton from '../../Core/SiteButton'
import Link from '../../Core/Link'

const BlurbContainer = styled.div`

   ${theme.below.sm} {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
   }

   .blurb {
      position: relative;
   }

   .blurb h5 {
      font-weight: 700;
   }

   .blurb-tag {
      position: absolute;
      background: ${props => props.tagBackgroundColor};
      font-size: 14px;
      color: ${props => props.tagTextColor};
      right: 32px;
      transform: translate(0, -50%);
      font-weight: 700;
      font-family: var(--font-industry);
      text-transform: uppercase;
      border-radius: 128px;
      top: 0;
   }

   .blurb-container {
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 8px;
      position: absolute;
      background-color:  ${props => props.backgroundColor};
      backdrop-filter: blur(5px);
      width: 25%;
      z-index: 10;
      transition: scale ease 0.25s, ease box-shadow 0.25s;
      scale: 1;
   }

   .blurb-container:hover {
      transition: scale 0.25s, box-shadow 0.25s;
      scale: 1.0025;
      box-shadow: 0 1px 32px rgba(0, 0, 0, 0.08);
   }

   .blurb-image-container {
      overflow: hidden;
   }

   .blurb-image-container-left {
      border-radius: 8px 0 0 8px;
   }

   .blurb-image-container-right {
      border-radius: 0px 8px 8px 0px;
   }

   .blurb-image {
      width: 100%;
      transition: all ease 0.5s;
      scale: 1;
      object-fit: cover;
      max-height: 60vh;
   }

   .blurb:hover .blurb-image {
      transition: all ease 0.5s;
      scale: 1.01;
   }

   /* RIGHT */

   .blurb-right .blurb-container {
      right: 0;
      transform: translate(50%, -50%);
   }

   @media (max-width: 1199px) {
      .blurb-container {
         width: 150%;
         padding: 16px;
      }

      .blurb-image {
         height: 100vw;
         object-fit: cover;
      }
   }

  ${theme.below.sm} {
      .blurb-container {
         width: 160%;
         padding: 0.5rem;
      }

      .blurb-container p {
         max-height: 90px;
      }

      .blurb-text a {
         margin-top: 1rem;
      }
  }
`

export const Blurb = ({ properties }) => {
   return (
      <BlurbContainer backgroundColor={properties?.backgroundColor} tagBackgroundColor={properties?.tagBackgroundColor} tagTextColor={properties?.tagTextColor} className={`${properties?.align == 'left' ? "pl-128" : "pr-128"} py-64`}>
         <div className="row m-0">
            {properties?.align == 'left' && (
               <div className="col-6 col-xl-2 p-0 m-0"></div>
            )}
            <div className={`col-6 col-xl-10 p-0 m-0 position-relative ${properties?.align == 'right' ? "blurb-right" : ""} blurb`}>
               <div className="blurb-container">
                  <div className="blurb-text p-32">
                     <div className="blurb-tag px-16 py-8">
                        <span>{properties.tagText}</span>
                     </div>
                     <TextContent text={properties?.content} style={{color: properties.textColor, overflowY: 'auto'}}></TextContent>
                     <SiteButton
                        style={{ padding: '0.8rem 0.8rem 0.8rem 1.5rem' }}
                        href={properties?.ctaLink}
                        type="submit"
                        styleType="solid"
                        color={properties?.buttonColor}
                        size="medium"
                        icon={true}
                     >
                        {properties?.ctaTitle}
                        <NavArrowRight
                           strokeWidth={2.0}
                           style={{ marginLeft: '1rem' }}
                        ></NavArrowRight>
                     </SiteButton>
                  </div>
               </div>
               <Link to={properties?.ctaLink}>
                  <div
                     className={`blurb-image-container ${
                        properties.align == 'left'
                           ? 'blurb-image-container-left'
                           : 'blurb-image-container-right'
                     }`}
                  >
                     <img
                        className="img-fluid blurb-image"
                        title="Starthälp"
                        src={properties?.img}
                        alt={properties?.altText}
                     />
                  </div>
               </Link>
            </div>
            {properties?.align == 'right' && (
               <div className="col-6 col-xl-2 p-0 m-0"></div>
            )}
         </div>
      </BlurbContainer>
   )
}