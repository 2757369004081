import { theme } from '../Theme'
import { styled } from 'linaria/react'
import { pxToRem } from '../../utils/css'

export const ImageCover = styled.div`
   &[data-has-background-image='true'] {
      ${theme.below.md} {
         background-image: ${props =>
            `url("${props.backgroundImage}?extend=copy&width=800&method=thumbnail&type=webp")`};
      }
      ${theme.above.md} {
         background-image: ${props =>
            `url("${props.backgroundImage}?extend=copy&width=940&method=thumbnail&type=webp")`};
      }
      ${theme.above.lg} {
         min-height: ${pxToRem(265)};
         background-image: ${props =>
            `url("${props.backgroundImage}?extend=copy&width=1400&method=thumbnail&type=webp")`};
      }
      background-size: cover;
      background-position: center;
   }
`
