import React from 'react'
import AccordionUI from '@jetshop/ui/Accordion/Accordion'

import { styled } from 'linaria/react'
import { NarrowMaxWidth } from '../Layout/MaxWidth'
import { theme } from '../Theme'
import { pxToRem } from '../../utils/css'
import { MinusIcon, PlusIcon } from '../ui/Icons'

const Header = styled.header`
   display: flex;
   justify-content: space-between;
   align-items: center;
   height: 56px;
   cursor: pointer;

   .toggler {
      color: ${theme.colors.text};
      font-size: ${theme.font.size.m};
      line-height: ${pxToRem(24)};
      font-weight: ${theme.font.weight.medium};
   }
`

const Wrapper = styled(NarrowMaxWidth)`
   cursor: pointer;
`

const Item = styled.div`
   &:first-child {
      border-top: 1px solid #dcdcdc;
   }
   border-bottom: 1px solid #dcdcdc;
`

const Content = styled.div`
   font-size: ${theme.font.size.s};
   line-height: ${pxToRem(20)};
   padding-bottom: ${theme.spacing.s};
`

export const Accordion = ({ children, ...props }) => {
   return (
      <AccordionUI {...props}>
         {({ openIndexes, handleClick, AccordionContent }) => {
            return (
               <Wrapper>
                  {children.map((item, index) => {
                     let isOpen = openIndexes.includes(index)
                     return (
                        <Item key={index}>
                           <Header onClick={() => handleClick(index)}>
                              <h3 className="toggler">{item.title}</h3>
                              {isOpen ? (
                                 <MinusIcon
                                    height="15px"
                                    style={{ marginRight: '10px' }}
                                 />
                              ) : (
                                 <PlusIcon
                                    height="15px"
                                    style={{ marginRight: '10px' }}
                                 />
                              )}
                           </Header>
                           <AccordionContent isOpen={isOpen}>
                              <Content>{item.text}</Content>
                           </AccordionContent>
                        </Item>
                     )
                  })}
               </Wrapper>
            )
         }}
      </AccordionUI>
   )
}
