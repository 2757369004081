import React, { useState, useEffect, useCallback } from 'react'
import { styled } from 'linaria/react'
import { theme } from '../Theme'

import MaxWidth from '../Layout/MaxWidth'
import LinkCard from './LinkCard'
import { ReactComponent as ChevronRight } from '../../svg/ChevronAltRight.svg'
import { ReactComponent as ChevronLeft } from '../../svg/ChevronAltLeft.svg'
import t from '@jetshop/intl'

const CarouselWrapper = styled('div')`
   max-width: 100%;
   overflow: hidden;
   margin: ${theme.spacing.m} 0;
`

const Embla = styled(MaxWidth)`
   ${theme.below.md} {
      padding: 0;
   }

   .container {
      display: flex;
      gap: ${theme.spacing.xs};
   }
   .slide {
      position: relative;
      flex: 0 0 100%;
   }
`

const CarouselNav = styled('div')`
   display: flex;
   gap: 1.5rem;
   align-items: center;
   margin-top: ${theme.spacing.xs};
   justify-content: center;
   line-height: 0;

   .dots {
      display: flex;
      gap: 0.65rem;
   }
`

const NavButton = styled('button')`
   opacity: ${props => (props.enabled ? '1' : '0.5')};
   pointer-events: ${props => (props.enabled ? 'initial' : 'none')};
   cursor: pointer;
   width: 0.65rem;
   background: none;

   svg {
      width: 100%;
      height: auto;

      * {
         fill: ${theme.colors.primary};
      }
   }
`

const DotButton = styled('button')`
   width: 0.75rem;
   height: 0.75rem;
   border-radius: 50%;
   background-color: ${props =>
      props.selected ? theme.colors.primary : theme.colors.tableLines};
`

export const LinkCardsCarousel = props => {
   // const [emblaRef, embla] = useEmblaCarousel({ align: 'start' })
   // const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
   // const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
   // const [selectedIndex, setSelectedIndex] = useState(0)
   // const [scrollSnaps, setScrollSnaps] = useState([])

   // const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
   // const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])
   // const scrollTo = useCallback(index => embla && embla.scrollTo(index), [
   //    embla,
   // ])

   // const onSelect = useCallback(() => {
   //    if (!embla) return
   //    setSelectedIndex(embla.selectedScrollSnap())
   //    setPrevBtnEnabled(embla.canScrollPrev())
   //    setNextBtnEnabled(embla.canScrollNext())
   // }, [embla, setSelectedIndex])

   // useEffect(() => {
   //    if (!embla) return
   //    onSelect()
   //    setScrollSnaps(embla.scrollSnapList())
   //    embla.on('select', onSelect)
   // }, [embla, setScrollSnaps, onSelect])

   const children = props.children
   return (
      // <CarouselWrapper>
      //    <Embla className="embla" ref={emblaRef}>
      //       <div className="container">
      //          {children &&
      //             children.map((child, i) => <LinkCard key={i} {...child} />)}
      //       </div>
      //       <CarouselNav>
      //          <NavButton
      //             aria-label={t('Previous image')}
      //             onClick={scrollPrev}
      //             enabled={prevBtnEnabled}
      //          >
      //             <ChevronLeft />
      //          </NavButton>
      //          <div className="dots">
      //             {scrollSnaps.map((_, index) => (
      //                <DotButton
      //                   aria-label={t('Image-indicator')}
      //                   key={index}
      //                   selected={index === selectedIndex}
      //                   onClick={() => scrollTo(index)}
      //                />
      //             ))}
      //          </div>
      //          <NavButton
      //             aria-label={t('Next image')}
      //             onClick={scrollNext}
      //             enabled={nextBtnEnabled}
      //          >
      //             <ChevronRight />
      //          </NavButton>
      //       </CarouselNav>
      //    </Embla>
      // </CarouselWrapper>
      <></>
   )
}

export default LinkCardsCarousel
