import React from 'react'

import { styled } from 'linaria/react'
import { css, cx } from 'linaria'

import { NarrowMaxWidth } from '../Layout/MaxWidth'
import { pxToRem } from '../../utils/css'
import { RichContentStyleWrapper } from './Text'
import { theme } from '../Theme'

const Container = styled.div`
   padding: ${pxToRem(30)};
   background-color: ${theme.colors.background};
   color: ${theme.colors.text};
   ${theme.above.lg} {
      padding: ${pxToRem(60)} 0;
      .two-columns {
         grid-template-columns: repeat(2, 1fr);
      }
      .three-columns {
         grid-template-columns: repeat(3, 1fr);
      }
      .four-columns {
         grid-template-columns: repeat(4, 1fr);
      }
   }
`

const column = css`
   display: grid;
   grid-gap: ${theme.spacing.xs};
`

export const TextColumn = ({ layout, children }) => {
   return (
      <RichContentStyleWrapper>
         <Container>
            <NarrowMaxWidth className={cx(column, layout)}>
               {children.map(
                  (item, index) =>
                     item.text && (
                        <div
                           key={index}
                           dangerouslySetInnerHTML={{ __html: item.text }}
                        />
                     )
               )}
            </NarrowMaxWidth>
         </Container>
      </RichContentStyleWrapper>
   )
}
export default TextColumn
