import React from 'react'
import { styled } from 'linaria/react'
import { theme } from '../Theme'
import {Helmet } from "react-helmet-async";
import Minus from '../../svg/Minus.svg'
import Plus from '../../svg/Plus.svg'

const Container = styled.div`
   background-color: white;
`

const AccordionPanel = styled.div`
   overflow: hidden;
   
   .accordion-item{
      border-radius: 0;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-width: 2px;
      font-family: var(--font-grotesk);
      font-family: 400;
   }

   .html-container {
      margin-top: 1rem
   }

   .html-container ol,ul {
      padding: revert;
      margin: revert;
      list-style: revert;
   }
   
   .accordion-collapse {
      transition: all 0.5s ease;
   }
   
   .accordion-title{
      font-weight: 700;
   }
   
   .accordion-item:first-of-type{
      border-top-left-radius: 0;
      border-top-right-radius: 0;
   }
   
   .accordion-item:last-of-type{
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: 0;
   }
   
   .accordion-button{
      font-size: 1.25rem;
      font-family: var(--font-grotesk);
      font-weight: 700;
      padding-left: 0;
      padding-right: 0;
   }
   
   .accordion-button:focus{
      box-shadow: none;
   }
   
   .accordion-button::after{
      width: 32px;
      height: 32px;
      background-image: url(${Plus});
      background-size: cover;
      transform: rotate(90deg);
      transition: transform 0.5s ease;
   }
   
   .accordion-button:not(.collapsed)::after{
      width: 32px;
      height: 32px;
      background-image: url(${Minus});
      transform: rotate(0deg);
      transition: transform 0.5s ease;
   }
   
   .accordion-body {
      padding-top: 1rem;
   }

   .accordion-body ol,ul {
      padding: revert;
      margin: revert;
      list-style: revert;
   }
   
   .accordion-button:not(.collapsed){
      background-color: unset;
      color: var(--color-black);
      box-shadow: none;
   }
   
   @media (min-width: 768px) {
      .accordion-button{
      font-size: 1.5rem;
      }
   }
`

export const FAQAccordion = props => {

   const GenerateStructureData = () => {
      let FAQStructureData = {
         "@context": "https://schema.org",
         "@type": "FAQPage",
         "mainEntity": []
      }

      for (const FAQItem of props?.children) {
         let FAQStructureQuestion = {
            "@type": "Question"
         }
         FAQStructureQuestion.name = FAQItem?.question
         FAQStructureQuestion.acceptedAnswer = {
            "@type": "Answer"
         }
         FAQStructureQuestion.acceptedAnswer.text = FAQItem?.answer
         FAQStructureData.mainEntity.push(FAQStructureQuestion) 
      }

      return FAQStructureData;
   }

   // const activatePanel = event => {
   //    event.currentTarget.classList.toggle('active')
   //    var panel = event.currentTarget.nextElementSibling
   //    if (panel.style.maxHeight) {
   //       panel.style.maxHeight = null
   //    } else {
   //       panel.style.maxHeight = panel.scrollHeight + 'px'
   //    }
   // }

   if (props?.title < 3) return <></>

   if (props?.title < 3) return <></>

   return (
      <Container className="pt-128 pb-64">
         <div className="container">
            {props?.children && (
               <Helmet>
                  <script type="application/ld+json">
                     {JSON.stringify(GenerateStructureData())}
                  </script>
               </Helmet>
            )}
            <AccordionPanel id="accordionPanel">
               <h3 className="accordion-title m-0">{props?.title}</h3>
               {props?.children.map((item, index) => {
                  return (
                     <div className="accordion-item">
                        <h2
                           className="accordion-header"
                           id={`panelsStayOpen-headingOne-${index}`}
                        >
                           <button
                              className="accordion-button collapsed mt-32"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#panelsStayOpen-collapseOne-${index}`}
                              aria-expanded="true"
                              aria-controls="panelsStayOpen-collapseOne"
                           >
                              {item?.question}
                           </button>
                        </h2>
                        <div
                           id={`panelsStayOpen-collapseOne-${index}`}
                           className="accordion-collapse collapse"
                           aria-labelledby="panelsStayOpen-headingOne"
                        >
                           <div className="accordion-body" dangerouslySetInnerHTML={{ __html: item?.answer }}>
                           </div>
                        </div>
                     </div>
                  )
               })}
            </AccordionPanel>
         </div>
      </Container>
   )
}
