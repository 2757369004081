import React from 'react'
import { styled } from 'linaria/react'
import { theme } from '../Theme'

const Space = styled.div`
   width: 100%;

   h1,
   h2,
   h3,
   h4,
   h5 {
      font-weight: ${theme.font.weight.medium};
   }

   &[data-spacing-size='small'] {
      height: ${theme.spacing.s};
   }

   &[data-spacing-size='medium'] {
      height: ${theme.spacing.m};
   }

   &[data-spacing-size='large'] {
      height: ${theme.spacing.l};
   }
`

export const Spacing = ({ size }) => <Space data-spacing-size={size} />
