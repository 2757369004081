import React from 'react'
import { styled } from 'linaria/react'
import { css, cx } from 'linaria'
import { Link } from 'react-router-dom'
import Spinner from './Spinner'
import t from '@jetshop/intl'
import { theme } from '../Theme'
import { pxToRem } from '../../utils/css'

export const buttonStyles = `
color: white;
background: ${theme.colors.primary};
cursor: pointer;
word-break: keep-all;

font-size: ${pxToRem(14)};
font-weight: ${theme.font.weight.bold};
text-decoration: none;
font-size: 14px;
height: 35px;
padding: 0 0.5rem;

display: flex;
align-items: center;
justify-content: center;
transition: opacity 0.2s ease-out;

color: var(--buttonColor);
background: var(--buttonBackground);
border: 1px solid var(--buttonBorderColor);

&:not([disabled]):hover {
   color: var(--buttonColorHover);
   background: var(--buttonBackgroundHover);
   border: 1px solid var(--buttonBorderColorHover);
}
`
const buttonClass = css`
   ${buttonStyles}
`

const bigButton = css`
   height: 45px;
   width: 200px;
`

function getCssVars(isInverted, isDisabled, color) {
   if (isDisabled) {
      return {
         '--buttonColor': theme.colors.white,
         '--buttonBorderColor': theme.colors.text,
         '--buttonBackground': theme.colors.text,
         '--buttonColorHover': theme.colors.white,
         '--buttonBackgroundHover': theme.colors.text,
         '--buttonBorderColorHover': theme.colors.text,
      }
   }
   if (isInverted) {
      return {
         '--buttonColor': theme.colors[color],
         '--buttonBorderColor': theme.colors[color],
         '--buttonBackground': 'white',
         '--buttonColorHover': 'white',
         '--buttonBackgroundHover': 'transparent',
         '--buttonBorderColorHover': 'white',
      }
   }
   return {
      '--buttonBorderColor': 'transparent',
      '--buttonColor': 'white',
      '--buttonBackground': theme.colors[color],
      '--buttonColorHover': 'white',
      '--buttonBackgroundHover': theme.colors[color + 'Dark'],
      '--buttonBorderColorHover': 'transparent',
   }
}

export const Button = ({
   to,
   href,
   color,
   isInverted,
   isBig,
   title,
   children,
   className,
   disabled,
   ...props
}) => {
   color = color ?? 'primary'

   return (
      <button
         disabled={disabled}
         className={cx(buttonClass, className, isBig && bigButton)}
         style={getCssVars(isInverted, disabled, color)}
         {...props}
      >
         {title ? title : children}
      </button>
   )
}

const StyledLinkButton = styled(Link)`
   text-decoration: none;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const LinkButton = ({
   className,
   title,
   children,
   isInverted,
   isBig,
   color = 'primary',
   disabled = false,
   href,
   to,
  secondary,
   ...props
}) => {
   if (href) {
      return (
         <a
            href={href}
            className={cx(buttonClass, className, isBig && bigButton)}
            style={getCssVars(isInverted, disabled, color)}
            {...props}
         >
            {title ? title : children}
         </a>
      )
   }

   return (
      <StyledLinkButton
         className={cx(buttonClass, className, isBig && bigButton)}
         style={getCssVars(isInverted, disabled, color)}
         to={to}
         {...props}
      >
         {title ? title : children}
      </StyledLinkButton>
   )
}

const StyledSpinner = styled(Spinner)`
   height: 25px;
   circle.path {
      stroke: white;
   }
`

const ButtonWithLoading = ({
   loading,
   loadingText = t('Hold on a moment...'),
   ...props
}) =>
   loading ? (
      <Button {...props}>
         <span>{loadingText}</span>
         <StyledSpinner />
      </Button>
   ) : (
      <Button {...props} />
   )

export default ButtonWithLoading
