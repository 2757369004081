import { styled } from 'linaria/react'
import { pxToRem } from '../../utils/css'
import { theme } from '../Theme'

const MaxWidth = styled('div')`
   max-width: calc(${theme.breakpoints.xl} + ${theme.spacing.xs} * 2);

   display: flex;
   flex-direction: column;
   justify-content: stretch;
   align-items: stretch;
   margin: 0 auto;
   position: relative;
   flex: 1 1 auto;
   width: 100%;
   padding: 0 ${theme.spacing.xs};
`

export const NarrowMaxWidth = styled(MaxWidth)`
   max-width: ${pxToRem(820)};
`

export default MaxWidth
