import React from 'react'
import { styled } from 'linaria/react'
import { theme } from '../../../Theme'
import { useIntl } from '@jetshop/intl'
import ArticleViewCard from './ArticleViewCard'
import ArticlesHeader from './ArticlesHeader'

const ArticlesContainer = styled.div`
   display: flex;
   overflow-x: auto;

   & {
      padding: 0.5rem;
      margin: -0.5rem;
   }

   .article-card {
      width: clamp(220px, 25vw, 240px);
      ${theme.above.xl} {
         width: 25%;
      }
      scroll-snap-align: start;
      transition: all 0.2s;
   }
`

const ArticlesViewer = ({ articlesData }) => {
   const t = useIntl()
   return (
      <div className="px-128 py-32">
         <ArticlesHeader
            title={articlesData?.name}
            ctaText={t('read all posts')}
            ctaLink={articlesData?.primaryRoute?.path}
         />
         <ArticlesContainer>
            <div className="d-flex flex-grow-1 gap-3 mb-2">
               {articlesData?.articles?.slice(0, 4)?.map((article, i) => {
                  return (
                     <ArticleViewCard
                        article={article}
                        key={i}
                     ></ArticleViewCard>
                  )
               })}
            </div>
         </ArticlesContainer>
      </div>
   )
}

export default ArticlesViewer
