import { styled } from 'linaria/react'
import { css } from 'linaria'
import { theme } from '../Theme'
import { pxToRem } from '../../utils/css'


const styles = `
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-variant: small-caps;
  margin-bottom: 0.75rem;
`

export const smallCaps = css`
   ${styles};
`
export const SmallCaps = styled('h1')`
   ${styles};
`

const headingStyles = `
   font-style: normal;
   font-weight: 600;
   font-family: ${theme.font.family.heading};
`

export const h1Style = `
   ${headingStyles}

   font-size: ${pxToRem(30)};

   ${theme.above.md} {
      font-size: ${pxToRem(40)};
   }
`
export const H1 = styled('h1')`${h1Style};`

export const h2Style = `
   ${headingStyles}

   font-size: ${pxToRem(26)};
   font-weight: 400;

   ${theme.above.md} {
      font-size: ${pxToRem(36)};
   }
`
export const H2 = styled('h2')`${h2Style}`

export const h3Style = `
   ${headingStyles}

   font-size: ${pxToRem(20)};

   ${theme.above.md} {
      font-size: ${pxToRem(26)};
   }
`
export const H3 = styled('h3')`${h3Style}`

export const h4Style = `
   ${headingStyles}
   font-size: ${pxToRem(20)};
`
export const H4 = styled('h4')`${h4Style};`

export const h5Style = `
   ${headingStyles}
   font-size: ${pxToRem(14)};
`
export const H5 = styled('h5')`${h5Style};`