import React from 'react'
import { styled } from 'linaria/react'
import PropTypes from 'prop-types';
import { theme } from '../../Theme';
const VideoContainer = styled.div`
   .video-title {
      color: black;
      font-size: ${props => props.titleSize ?? 'calc(1vw + 1rem)'};
      ${theme.above.xl} {
         font-size: ${props => props?.titleSize ?? '28px'};
      }
   }

   .embed-responsive {
      position: relative;
      display: block;
      width: 100%;
      padding: 0;
      overflow: hidden;
   }

   .embed-responsive::before {
      display: block;
      content: '';
      padding-top: 56.25%;
   }

   .embed-responsive-item {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
   }
`

export const VideoPlayer = ({ title, titleSize, videoId }) => {
   return (
      <VideoContainer titleSize={titleSize}>
         <h2 className="video-title mb-3">{title}</h2>
         <div className="embed-responsive">
            <iframe
               className="embed-responsive-item"
               src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0`}
               title="YouTube video"
               allowFullScreen
            ></iframe>
         </div>
      </VideoContainer>
   )
}

VideoPlayer.propTypes = {
   title: PropTypes.string.isRequired,
   titleSize: PropTypes.string,
   videoId: PropTypes.string.isRequired,
 };
 
//  VideoPlayer.defaultProps = {
//    titleSize: '28px',
//  };
