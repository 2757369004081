import React, { useState, useEffect } from 'react'
import { VideoPlayer } from './VideoPlayer'
import { TextContent } from './TextContent'
import { ImageTextContent } from './ImageTextContent'
import { styled } from 'linaria/react'


const Image = styled.img`
    max-width: 100%;
`

export const OneColumn = ({ properties }) => {
   return (
         <div
            style={{
               maxWidth: properties?.fullWidth && '100%',
               padding: properties?.fullWidth && '0px',
            }}
            className={`one-column px-128 ${properties?.marginTop} ${properties?.marginBottom}`}
         >
         <div style={{paddingLeft: "0.5rem", paddingRight: "0.5rem"}}>
            {(() => {
                  if (properties?.children[0]?.videoId) {
                     return (
                        <VideoPlayer title={properties?.children[0]?.title} videoId={properties?.children[0]?.videoId}></VideoPlayer>
                     )
                  } else if (properties?.children[0]?.content) {
                     return (
                        <TextContent text={properties?.children[0]?.content} />
                     )
                  } else if (properties?.children[0]?.image && properties?.children[0]?.customContent) {
                     return (
                        <>
                        <Image src={properties?.children[0]?.image} alt={properties?.children[0]?.altText}></Image>
                        <ImageTextContent innerClassName={properties?.children[0]?.marginTopCustomContent} customContent={properties?.children[0]?.customContent} />
                        </>
                     )
                  } else {
                     return (
                        <Image src={properties?.children[0]?.img} alt={properties?.children[0]?.altText}></Image>
                     )
                  }
            })()}
        </div>
    </div>
   )
}