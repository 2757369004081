import React, { useContext, useState, useEffect }  from 'react'
import { styled } from 'linaria/react'
import CategoryFallback from '../../svg/fallback-category.webp'
import { SiteContext } from '../Global/SiteContext'
import { Above, Below } from '@jetshop/ui/Breakpoints'
import BannerSkeletonSlim from '../Core/Skeletons/BannerSkeletonSlim'
import { theme } from '../Theme'

const PageHeroContainer = styled.div`
   background: #e9e9e9;

   .page-hero-title {
      font-size: 2.8rem;
      font-weight: 700;
   }

   .page-hero-category-title {
      font-size: 1.1rem;
      font-family: var(--font-grotesk);
      font-family: 600;
   }

   .page-hero-text {
      font-family: var(--font-grotesk);
      font-family: 400;
   }

   .page-hero-logo {
   }

   .page-hero-logotype {
      z-index: 10;
      object-fit: contain !important;
      width: 10rem !important;
      margin: unset !important;
      height: auto !important;
      max-height: 4rem;
      left: 2rem !important;
      bottom: 2rem !important;
      top: unset !important;
   }

   .page-hero-second {
      position: relative;
      border-radius: 2rem 0 0 0;
      overflow: hidden;
   }

   .page-hero-second img {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
      height: 100%;
      width: 100%;
      object-fit: cover;
   }

   .page-hero-first-top > ul {
      margin-bottom: 0px !important;
   }

   @media (max-width: 1200px) {
      .page-hero-title {
         font-size: 2rem;
         color: #fff;
         text-shadow: 0 0 2rem #000;
         padding-bottom: 1rem;
      }
      .page-hero-first {
         order: 2;
      }

      .breadcrumb {
         padding-bottom: 0.5rem;
         padding-top: 5rem;
     }
   
     .breadcrumb li a {
         color: white !important;
     }
   
     .breadcrumb li {
         color: white !important;
     }

      .page-hero-second {
         border-radius: 0 !important;
      }
      .page-hero-second::before {
         content: " ";
         height: 100%;
         bottom: 0;
         width: 100%;
         z-index: 1;
         display: inline-block;
         position: absolute;
         background: linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%);
      }
   }

   .breadcrumbs-container {
      ${theme.below.xl} {
         padding: 4rem;
         padding-bottom: 1rem;
      }

      ${theme.below.md} {
         padding: 1.5rem;
         padding-bottom: 1rem;
      }
  }
`

export const HeroSection = ({
   title,
   text,
   backgroundColor = 'blue',
   backgroundImage,
   logo,
   ...props
}) => {
   const { GetBreadCrumbs } = useContext(SiteContext)
   const [fakeLoader, setFakeLoader] = useState(null)

   useEffect(() => {
      setFakeLoader(title)
   }, [setFakeLoader, title])   

   if(!fakeLoader){
      return <BannerSkeletonSlim></BannerSkeletonSlim>
   }

   return (
      <PageHeroContainer>
         <Below breakpoint="xl">
            <div className="row p-0 m-0">
               <div className="page-hero-first col-12 col-xl-6 pt-16 pr-32 pl-128 d-flex flex-column justify-content-between">
                  <div className="page-hero-first-bottom d-flex flex-column">
                     <p className="page-hero-text py-16 m-0">{text}</p>
                  </div>
               </div>
               <div className="page-hero-second col-12 col-xl-6 p-0">
                  <div className="page-hero-image">
                     <div className='breadcrumbs-container'>
                        {GetBreadCrumbs("breadcrumb position-relative z-2 m-0")}
                     </div>
                     <h2 className="page-hero-title d-block d-xl-none position-relative z-2 px-128 m-0">
                        {title}
                     </h2>
                     { logo && <img className="page-hero-logotype" alt="Hero logo"  src={logo} /> }
                     <img
                        alt="Default"
                        className="img-fluid"
                        src={backgroundImage ?? CategoryFallback}
                     />
                  </div>
               </div>
            </div>
         </Below>
         <Above breakpoint="xl">
            <div className="row p-0 m-0">
               <div className="page-hero-first col-12 col-xl-6 pt-64 pb-32 pr-32 pl-128 d-flex flex-column justify-content-between">
                  <div className="page-hero-first-top d-flex flex-column">
                     {GetBreadCrumbs()}
                     <h2 className="page-hero-title d-none d-xl-block">{title}</h2>
                  </div>
                  <div className="page-hero-first-bottom d-flex flex-column">
                     <p className="page-hero-text py-16 m-0">{text}</p>
                  </div>
               </div>
               <div className="page-hero-second col-12 col-xl-6 p-0">
                  <div className="page-hero-image">
                     <div className="page-hero-logo">
                        { logo && <img className="page-hero-logotype" alt="Hero logo" src={logo} /> }
                        <img
                           alt="Default"
                           className="img-fluid"
                           src={backgroundImage ?? CategoryFallback}
                        />
                     </div>
                  </div>
               </div>
            </div>
         </Above>
      </PageHeroContainer>
   )
}


