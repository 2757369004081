import React from 'react';
import { styled } from 'linaria/react'
import SiteButton from '../SiteButton';
import { NavArrowRight } from 'iconoir-react';
import CategoryFallback from '../../../svg/fallback-category.webp'
import { theme } from '../../Theme';

const VideBannerContainer = styled.div`
   position: relative;
   background-color: black;
   height: calc(50vh - 72px);
   min-height: 25rem;
   width: 100%;
   overflow: hidden;

   video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: 100%;
      ${theme.above.lg} {
         width: 100%;
         height: auto;
      }
      z-index: 0;
      -ms-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
   }

   .video-title {
      font-weight: 700;
      text-shadow: 0 0 2rem #000;
   }

   .video-text {
      font-size: 18px;
      font-family: var(--font-grotesk);
      text-shadow: 0 0 1.5rem #000;
      font-weight: 500;
   }

   .video-background-content {
      position: relative;
      z-index: 2;
   }

   .video-background-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(
         180deg,
         rgba(228, 228, 228, 0) 50%,
         rgba(228, 228, 228, 1) 100%
      );
      z-index: 1;
   }
`

const VideoBanner = ({ properties }) => {
    const { title, subTitle, ctaText, ctaLink, videoUrl, hideGradientOverlay} = properties

    return (
       <VideBannerContainer>
          {!hideGradientOverlay && <div className="video-background-overlay"></div>}
          <video
             playsInline="playsInline"
             autoPlay="autoplay"
             muted="muted"
             loop="loop"
             poster={CategoryFallback}
          >
             <source src={videoUrl} type="video/mp4" />
          </video>

          <div className="video-background-content px-128 h-100">
             <div className="d-flex h-100 pb-64 align-items-end">
                <div className="w-100 text-white">
                   <h1 className="video-title">{title}</h1>
                   <p className="video-text">{subTitle}</p>
                   <SiteButton
                      style={{ padding: '0.8rem 0.8rem 0.8rem 1.5rem' }}
                      href={ctaLink}
                      type="submit"
                      styleType="solid"
                      color="blue"
                      size="large"
                      icon={true}
                   >
                      {ctaText}
                      <NavArrowRight
                         strokeWidth={2.0}
                         style={{ marginLeft: '1rem' }}
                      ></NavArrowRight>
                   </SiteButton>
                </div>
             </div>
          </div>
       </VideBannerContainer>
    )
}
 
export default VideoBanner;