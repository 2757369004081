import React, { useState, useEffect } from 'react'
import MaxWidth from '../../Layout/MaxWidth'
import { styled } from 'linaria/react'
import { TextContent } from './TextContent'
import { LinkButton } from '../../ui/Button'
import { theme } from '../../Theme'
import { NavArrowRight } from 'iconoir-react'
import SiteButton from '../../Core/SiteButton'
import Link from '../../Core/Link'
import { isValidString } from '../../Core/Util/Helpers'

//${props => props.tagBackgroundColor};
const ContentWidgetContainer = styled.div`
`

const ContentWidgetItem = styled.div`
.article-wrap {
    width: 100%;
 }

 .article-wrap:hover .article-image img {
    scale: 1.025;
    rotate: 0.15deg;
    transition: all ease 0.5s;
    filter: saturate(125%);
 }

 .article-content-wrap {
    position: relative;
    word-break: break-word;
 }

 .article-image {
    overflow: hidden;
 }

 .article-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 60vh;
    min-height: 60vh;
    filter: saturate(100%);
    scale: 1.01;
    rotate: 0deg;
    transition: all ease 0.5s;
    ${theme.below.xl} {
      max-height: 40vh;
      min-height: 40vh;
    }
 }

 .article-tag {
    position: absolute;
    background: ${props => props.tagBackgroundColor};
    font-size: 14px;
    color: ${props => props.tagTextColor};
    right: 32px;
    transform: translate(0, -50%);
    font-weight: 700;
    font-family: var(--font-industry);
    text-transform: uppercase;
    border-radius: 128px;
 }

 .article-content {
    background: ${props => props.backgroundColor};
 }

 .article-content h3 {
    font-weight: 700;
    overflow: hidden;
 }

 @media (max-width: 1200px) {
    .article-content {
       padding-top: 2rem !important;
    }

    .article-image img {
       max-height: 60vw;
       min-height: 60vw;
    }
 }
`

const getSizeClassName = (size) => {
    //switch case on bootstrap col
    switch (size) {
        case "25%": return "col-6 col-lg-3"
        case "50%": return "col-auto col-lg-6"
        case "75%": return "col-auto col-lg-9"
        case "100%": return "col-12 col-lg-12"
        default: return "col-12 col-lg-12"
    }
}

export const ContentWidget = ({ properties }) => {
   return (
      <ContentWidgetContainer className="container-fluid py-64">
         <div className="row">
            {properties?.children?.map((contentItem, i) => {
               return (
               
                  <ContentWidgetItem
                     key={i}
                     className={`${getSizeClassName(
                        contentItem?.width
                     )} p-0 m-0 d-flex`}
                     backgroundColor={contentItem?.backgroundColor}
                     tagBackgroundColor={contentItem?.tagBackgroundColor}
                     tagTextColor={contentItem?.tagTextColor}
                  >
                     <div className="article-wrap d-flex flex-column flex-grow-1">
                        <div className="article-image col-12">
                           <Link
                              to={contentItem?.ctaLink}
                              className="m-0 p-0 w-100"
                           >
                              <img
                                 className="img-fluid"
                                 src={contentItem?.backgroundImage}
                                 alt={contentItem?.backgroundImageAlt}
                              />
                           </Link>
                        </div>
                        <div className="article-content-wrap d-flex flex-grow-1">

                        { contentItem?.tagText && isValidString(contentItem?.tagText) && 
                           <div className="article-tag px-16 py-8">
                              <span>{contentItem?.tagText}</span>
                           </div>
                        }


                           <div className="article-content col-12 p-32 d-flex flex-column align-items-baseline">
                              <h3 style={{ color: contentItem?.textColor }}>
                                 {contentItem?.title}
                              </h3>
                              <p
                                 className="d-flex flex-grow-1"
                                 style={{ color: contentItem?.textColor }}
                              >
                                 {contentItem?.content}
                              </p>
                              <SiteButton
                                 style={{
                                    padding: '0.8rem 0.8rem 0.8rem 1.5rem',
                                 }}
                                 className="mt-16"
                                 href={contentItem?.ctaLink}
                                 type="submit"
                                 styleType="solid"
                                 color={contentItem?.buttonColor}
                                 size="medium"
                                 icon={true}
                              >
                                 {contentItem?.ctaText}
                                 <NavArrowRight
                                    strokeWidth={2.0}
                                    style={{ marginLeft: '1rem' }}
                                 ></NavArrowRight>
                              </SiteButton>
                           </div>
                        </div>
                     </div>
                  </ContentWidgetItem>
               )
            })}
         </div>
      </ContentWidgetContainer>
   )
}