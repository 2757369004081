
export const fetchKundoDialogById= async (id) => {
    try {
       const response = await fetch(
          `https://verktygsboden.proxy.digifi.se/api/kundo/dialogs/${id}`,
          {
             method: 'GET',
             headers: {
                'Content-Type': 'application/json',
             }
          }
       )
       return await response.json();
    } catch (error) {
       console.error(error)
    }
}

export const fetchLatestKundoDialogs= async () => {
   try {
      const response = await fetch(
         `https://verktygsboden.proxy.digifi.se/api/kundo/latest`,
         {
            method: 'GET',
            headers: {
               'Content-Type': 'application/json',
            }
         }
      )
      return await response.json();
   } catch (error) {
      console.error(error)
   }
}