import React from 'react'

import { styled } from 'linaria/react'
import { theme } from '../Theme'
import { NarrowMaxWidth } from '../Layout/MaxWidth'

const Container = styled('div')`
   padding-top: ${theme.spacing.xxs};
   padding-bottom: ${theme.spacing.s};
`

export const RichContentStyleWrapper = styled.div`
   h1,
   h2,
   h3,
   h4 {
      margin-bottom: ${theme.spacing.xs};
   }
`

export const Text = ({ text }) => {
   return (
      <Container className='text-container px-128'>
         <RichContentStyleWrapper>
            <div dangerouslySetInnerHTML={{ __html: text }} />
         </RichContentStyleWrapper>
      </Container>
   )
}
