import React from 'react'
import { styled } from 'linaria/react'
import { theme } from '../Theme'
import { css, cx } from 'linaria'
import { pxToRem } from '../../utils/css'
import MaxWidth from '../Layout/MaxWidth'

const Container = styled(MaxWidth)`
   grid-template-columns: 1fr;
   ${theme.above.md} {
      .two-videos {
         grid-template-columns: 1fr 1fr;
      }
      .three-videos {
         grid-template-columns: 1fr 1fr 1fr;
      }
   }
   .video-responsive {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
      margin: ${pxToRem(10)} 0;
      iframe {
         left: 0;
         top: 0;
         height: 100%;
         width: 100%;
         position: absolute;
      }
   }
`
const Wrapper = styled.div``

const grid = css`
   display: grid;
   grid-gap: ${theme.spacing.xs};
   gap: ${theme.spacing.xs};
`

export const EmbedYoutube = ({ layout, children }) => {
   return (
      <Container className={cx(grid)}>
         <Wrapper className={cx(grid, layout)}>
            {children &&
               children.map((item, index) => (
                  <div className="video-responsive" key={index}>
                     <iframe
                        key={index}
                        src={`https://www.youtube.com/embed/${item.videoId}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                     />
                  </div>
               ))}
         </Wrapper>
      </Container>
   )
}

export default EmbedYoutube
