import React from 'react';
import { styled } from 'linaria/react'
import { theme } from '../../Theme';
import { NavArrowLeft } from 'iconoir-react';
import CategoryFallback from '../../../svg/fallback-category.webp'
import { Above, Below } from '@jetshop/ui/Breakpoints';

const BannerSkeletonContainer = styled.div`
   & {
      background: #E9E9E9;
   }

   .width-1{
      width: 200px;
   }
   
   .width-2{
      width: 100px;
   }

   .button-tag {
      border-radius: 50px;
      font-family: var(--font-industry);
      font-weight: 700;
      color: #fff;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      white-space: nowrap;
   }

   .page-hero-second{
      position: relative;
      border-radius: 2rem 0 0 0;
      overflow: hidden;
      ${theme.below.xl}{
         border-radius: 0;
      }
   }

   .category-tags-container-mobile {
      ${theme.below.xl}{
         margin-bottom: 2.75rem !important;
      }
   }

   .page-hero-image {
        ${theme.below.md}{
            margin: 2.5rem 0;
        }

        ${theme.below.sm}{
            margin: 3.0rem 0;
        }
   }

   .page-hero-second img {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
      height: 100%;
      width: 100%;
      object-fit: cover;
  }

   .button-tag-wrapper .button-tag:last-child {
      margin-right: 0;
   }
   
   .button-tag--active {
      background-color: rgb(var(--color-vb-gray-3));
      transition: background-color 0.5s ease;
   }
   
   .button-tag--active:hover {
      background-color: rgba(var(--color-vb-gray-3), 0.75);
      transition: background-color 0.5s ease;
   }
   
   .button-tag--default {
      background-color: var(--color-vb-blue);
      transition: background-color 0.5s ease;
   }
   
   .button-tag--default:hover {
      background-color: var(--color-vb-blue-darker);
      transition: background-color 0.5s ease;
   }

   .button-tag--icon {
      width: fit-content;
      background-color: rgb(var(--color-vb-gray-3));
      transition: background-color 0.5s ease;
  }
`

const BannerSkeletonSlim = () => {
   return (
      <BannerSkeletonContainer>
         <div className="row p-0 m-0">
            <div className="page-hero-first col-12 col-xl-6 pt-64 pb-32 pr-32 pl-128 flex-column justify-content-between d-none d-xl-flex">
               <div className="page-hero-first-top d-flex flex-column">
                  <h2 className="page-hero-title d-none d-xl-block">
                     <span className="placeholder placeholder-wave w-50"></span>
                  </h2>
                  <h2 className="page-hero-title d-none d-xl-block">
                     <span className="placeholder placeholder-wave w-50"></span>
                  </h2>
                  <h2 className="page-hero-title d-none d-xl-block">
                     <span className="placeholder placeholder-wave w-50"></span>
                  </h2>
               </div>
            </div>
            <div className="page-hero-second col-12 col-xl-6 p-0">
               <div className="page-hero-image px-128 py-128">
                  <h2 className="page-hero-title d-block d-xl-none position-relative z-2 m-0">
                     <div className="placeholder w-50"></div>
                  </h2>
                  <img
                     className="img-fluid placeholder placeholder-wave"
                     alt="Default"
                     src={CategoryFallback}
                  />
               </div>
            </div>
         </div>
      </BannerSkeletonContainer>
   )
}

export default BannerSkeletonSlim
