import React from 'react'
import { styled } from 'linaria/react'
import { theme } from '../../../Theme'
import Image from '@jetshop/ui/Image/Image'
import SiteButton from '../../../Core/SiteButton'
import { NavArrowRight } from 'iconoir-react'
import { useIntl } from '@jetshop/intl'
import CategoryFallback from '../../../../svg/fallback-category.webp'
import Link from '../../../Core/Link'

const ArticleCardContainer = styled.div`
   a {
      font-family: var(--font-grotesk);
      color: white;
      text-decoration: none;
   }

   .article-card-description {
   }

   .line-clamp-2-article-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
   }

   .line-clamp-4-article-text {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
   }

   &.article-card {
      background: white;
      overflow: hidden;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      width: 100%;
   }

   .article-card-image [data-flight-image-placeholder] img,
   .article-card-image [data-flight-image] img {
      margin-top: 0rem;
      padding: 0rem;
   }

   .article-card-text {
      align-items: flex-start;
      span {
         font-family: var(--font-grotesk);
         font-size: 0.9rem;
         font-weight: 500;
         ${theme.above.md} {
            font-size: 1rem;
         }
      }
   }

   .article-card-title {
      font-size: 1rem;
      font-weight: 600;
      ${theme.above.md} {
         font-size: 1.2rem;
      }
   }

   .article-card-title a {
      font-family: var(--font-industry);
      color: black;
   }
`

const ArticleViewCard = ({ article }) => {
   const { title, articleText, imageSrc, routePath } = article ?? {}
   const t = useIntl()

   return (
      <ArticleCardContainer className="article-card br-8 d-flex flex-column">
         <div className="position-relative">
            <Link to={routePath}>
               <Image
                  cover={imageSrc ? false : true}
                  className="article-card-image"
                  src={imageSrc ?? CategoryFallback}
                  alt={`${title ? `${title}-image` : 'article-image'}`}
                  aspect="1:1"
               ></Image>
            </Link>
         </div>
         <div className="article-card-description d-flex flex-column p-16 flex-grow-1">
            <div className="article-card-title lh-1 pb-16 pt-1 d-flex">
               <div className="line-clamp-2-article-title">
                  <Link to={routePath}>{title}</Link>
               </div>
            </div>

            <div className="d-flex article-card-text flex-grow-1">
               <span className="line-clamp-4-article-text">{articleText}</span>
            </div>
            <div className="col-auto mt-3">
               <SiteButton
                  className="py-8 px-16"
                  href={routePath}
                  styleType="solid"
                  color="blue"
                  size="medium"
                  icon={true}
               >
                  <span>{t('go to post')}</span>
                  <NavArrowRight
                     strokeWidth={2.0}
                     style={{ marginLeft: '0.5rem' }}
                  ></NavArrowRight>
               </SiteButton>
            </div>
         </div>
      </ArticleCardContainer>
   )
}

export default ArticleViewCard
